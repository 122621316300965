import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SearchResultComponent } from '../search-result/search-result.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-search-result-tenant',
    imports: [MatIconModule],
    templateUrl: './search-result-tenant.component.html',
    styleUrl: './search-result-tenant.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultTenantComponent extends SearchResultComponent {}
