<div class="app-container" [class.has--rail]="user$() && loaded$()" [class.has--sidenav]="hasSidebarItems$()">
  <section class="app">
    @if (user$() && loaded$()) {
      <aside class="app__navbar">
        <app-side-nav
          #sideNav
          (triggerTenantSwitcher)="tenantSwitcher(null)"
          (triggerSearch)="commandPalette(null)"
          (hasSidebar)="hasSidebarItems$.set($event)"
          [user]="user$()"
          [menu]="'main-menu' | fromMenu"
          [theme]="theme$()"
          [user]="user$()"
          [hasTenants]="!!user$()?.tenants?.length"
          (toggleOverlay)="toggleMenu()"
          (closeOverlay)="closeMenu()"
          (openOverlay)="openMenu()"
          [overlayOpen]="menuOpen$()"
        ></app-side-nav>
      </aside>
    }
    @if (dictionaryLoaded$()) {
      <main class="app__main">
        <router-outlet #outlet="outlet" class="router" [@routerFade]="prepareRoute(outlet)"></router-outlet>
      </main>
    }
  </section>

  @if (testGridEnabled) {
    <app-grid-overlay></app-grid-overlay>
  }

  @if (loading$()) {
    <mat-progress-bar class="position-fixed top-0 left-0 right-0 z-3" mode="indeterminate"></mat-progress-bar>
  }
</div>
@if (dictionaryLoaded$()) {
  <fstr-cookie-notice
    [title]="'cookies:titel' | fromDictionary"
    [text]="'cookies:tekst' | fromDictionary"
    [buttonAccept]="'cookies:accepteer-knop-tekst' | fromDictionary"
    [buttonReject]="'cookies:afwijzen-knop-tekst' | fromDictionary"
  ></fstr-cookie-notice>
}
