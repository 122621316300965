import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { SearchResultComponent } from '../search-result/search-result.component';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { HighlightTextPipe } from '../../pipes';

@Component({
    selector: 'app-search-result-menu',
    imports: [MatIconModule, MatChipsModule, HighlightTextPipe],
    templateUrl: './search-result-menu.component.html',
    styleUrl: './search-result-menu.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultMenuComponent extends SearchResultComponent {}
