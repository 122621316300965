import { Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { FromDictionaryPipe } from '@teamfoster/sdk/dictionary-ngrx';
import { Store } from '@ngrx/store';
import { SetTitle } from './store';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-404',
  imports: [FromDictionaryPipe, RouterLink, MatButtonModule],
  template: `
    <article class="error-404 section-inset-y">
      <div class="container text-center">
        <h1 class="mat-display-medium">{{ '404:titel' | fromDictionary }}</h1>
        <p class="text-max-width mx-auto mat-body-large">
          {{ '404:tekst' | fromDictionary }}
        </p>
        <div class="error-404__content text-center mt-4">
          <a mat-flat-button [routerLink]="'/'">Terug naar dashboard</a>
        </div>
      </div>
    </article>
  `,
})
export class NotFoundComponent {
  code = 404;
  private store = inject(Store);

  constructor(private router: Router) {
    const statusCodeFromUrl = parseInt(this.router.url.replace(/\//g, ''));
    this.code = statusCodeFromUrl ? statusCodeFromUrl : 404;

    this.store.dispatch(SetTitle({ title: this.code.toString() }));
  }
}
