import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { SearchResult } from '../../models';
import { NgClass } from '@angular/common';
import { HighlightTextPipe } from '../../pipes';

@Component({
    selector: 'app-search-result',
    imports: [HighlightTextPipe],
    templateUrl: './search-result.component.html',
    styleUrl: './search-result.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultComponent {
  result = input.required<SearchResult>();
  searchQuery = input<string>('');
}
